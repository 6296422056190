import React, { useEffect, useState } from 'react';
import "../FavoriteProduct/HomeFavorite.css";
import ProductData from '../ProductData';
import Productcart from '../../Page/Productcart';
import axios from 'axios';


const HomeFavorite = () => {

    const [homeProduct, setHomeProduct] = useState([]);
    const Productshort = homeProduct.slice(0, 8);

    console.log("skdnvsnvsn",homeProduct)



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://jutiepie.in/api/product.php?&action=productFetch&limit=8&page=1&category_slug=all');
                setHomeProduct(response.data.list);
                console.log("prodyct slsjdncskjdn",response)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);


    return (
        <>
            <section className='Home-Section-4'>
                <div className='container'>
                    <div className='Home-Section-3-head-con'>
                        <h2 className='Smalltitle'>All Product Shop</h2>
                        <h3 className='Headtitle'>Customer Favorite Style Product</h3>
                    </div>
                </div>

                <div className='Home-Section-4-Filter-Products'>
                    <div className='Gallery-Section-2'>
                        <div className='Gallery-Card'>
                            <div className='container'>
                                <div className='row'>
                                    {
                                        Productshort.map((product) => {

                                            // const { ProductImage01, productcategory, productactualprice, productofferprice } = elem
                                            return (
                                                <>
                                                    <div className="col-lg-3 col-sm-6 my-3">
                                                        <Productcart product={product} />
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeFavorite