import React, { useEffect, useState } from 'react';
import "../Css/NavBar.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Dropdown, Space, Typography, Button, Menu } from "antd";
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../Images/Logo.png";
import { BiLogoFacebook, BiLogoWhatsapp } from 'react-icons/bi';
import { IoIosCall } from 'react-icons/io';
import { IoBagOutline } from 'react-icons/io5';
import { CiSearch } from 'react-icons/ci';
import { LiaUserSolid } from 'react-icons/lia';
import { useDispatch, useSelector } from "react-redux";
import { getProfile, getToken } from "../services/localStorageServices";
import { AiOutlineTwitter, AiOutlineInstagram, AiOutlineYoutube } from 'react-icons/ai';
import AddtoCart from '../Page/AddtoCart';
import { FiMail } from 'react-icons/fi';
import { useCartContext } from '../context/addToCart';
import { setUserToken } from '../features/authSlice';
import { GoogleLogout } from 'react-google-login';

const NavBar = () => {


    // google logout 

    const clientId = "413735203093-tt019nmdfqqkff5vf69lhskudqtph6m0.apps.googleusercontent.com";

    ///// end google logout 
    const { cartValue } = useCartContext();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // on scroll navbar stick 

    const [isScrolled, setIsScrolled] = useState(false);
    const handleScroll = () => {
        const scrollTop = window.scrollY;
        setIsScrolled(scrollTop > 0);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // end on scroll navbar stick 


    const { cartCount } = useCartContext();
    const { access_token } = getToken();
    const { profile } = getProfile();

    const { token } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(setUserToken({ token: access_token }));
    }, [access_token, dispatch]);

    const logoutHandle = () => {
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('profile');
        localStorage.clear();
        sessionStorage.clear();
        navigate('/');
    };

    // Function to close the navbar toggler
    const closeNavbar = () => {
        const navbarToggler = document.querySelector('.navbar-toggler');
        if (navbarToggler) {
            navbarToggler.click();
        }
    };


    return (
        <>

            <section className='NavBar-upper-Section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='NavBarSocial-Section'>
                                <div className='Navbar-Social-Section-con'>
                                    <a href='https://www.facebook.com/jutiepiee/' target='blank'>
                                        <BiLogoFacebook />
                                    </a>
                                    <a href="https://wa.me/+917304384413?text=" target='blank'>
                                        <BiLogoWhatsapp />
                                    </a>
                                    <a href='https://www.instagram.com/jutie_pie/' target='blank'>
                                        <AiOutlineInstagram />
                                    </a>
                                    <a href="mailto: jutiepie99@gmail.com" target='blank'>
                                        <FiMail />
                                    </a>
                                </div>
                                <div className='Navabr-Upper-contact'>
                                    <a href="tel:+91 73043 84413">
                                        <IoIosCall />
                                        <span>+91 73043 84413</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>

                            <div className='login-Navbar-main-con'>
                                <div className='Responsive-Navbar-Profile-con'>
                                    {token ? (
                                        <div className='LoginProfile'>
                                                <div class="dropdown">
                                                    <button class="dropbtn" icon={<LiaUserSolid />}> <LiaUserSolid /> {profile.username}</button>
                                                    <div class="dropdown-content">
                                                        <a href="#" onClick={logoutHandle}>Log Out</a>
                                                        <Link to="/Profiles">Profile</Link>
                                                    </div>
                                                </div>
                                        </div>
                                    ) : (
                                        <Nav.Link href="#features" className="signinbuttionsection">
                                            {" "}
                                            <Link className="nev-color" to="/account/sign-in">
                                                <button className="sign-up-button"><LiaUserSolid />Sign In</button>
                                            </Link>
                                        </Nav.Link>
                                    )}
                                    {token ? (
                                        <>
                                        </>
                                    ) : (
                                        <Nav.Link href="#features" className="signinbuttion">
                                            <Link className="nev-color" to="/account/sign-in">
                                                <button className="sign-up-button"><LiaUserSolid />Sign In</button>
                                            </Link>
                                        </Nav.Link>
                                    )}
                                </div>
                                <div className='Responsive-Navbar-Cart-con'>
                                    <div className='Search-bag-con-res'>
                                        <div className='navbar-addtobagbag-con'>
                                            <button
                                                class="btn btn-primary"
                                                type="button"
                                                data-bs-toggle="offcanvas"
                                                data-bs-target="#offcanvasRight"
                                                aria-controls="offcanvasRight"
                                            >
                                                <IoBagOutline />
                                            </button>
                                            <span>({cartValue.length})</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Navbar collapseOnSelect expand="lg" variant="dark" className={isScrolled ? 'sticky-navbar' : ''}>
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto navbar-items">
                            <Link to="/" className="nav-link" onClick={closeNavbar}>Home</Link>
                            <Link to="/Product/all" className="nav-link" onClick={closeNavbar}>Product</Link>
                            <Link to="/About" className="nav-link" onClick={closeNavbar}>About</Link>
                            <Link to="/Contact" className="nav-link" onClick={closeNavbar}>Contact</Link>
                        </Nav>
                        <Navbar.Brand>
                            <Link to="/">
                                <div className='mid-logo'>
                                    <div className='LogoImage'>
                                        <img src={Logo} alt="Logo" />
                                    </div>
                                </div>
                            </Link>
                        </Navbar.Brand>
                        <Nav className="me-auto navabr-search-con">
                            {/* <div className='uppernavbar-search'>
                                {token ? (
                                    <div className='LoginProfile'>
                                        <div class="dropdown">
                                            <button class="dropbtn" icon={<LiaUserSolid />}> {profile.username}</button>
                                            <div class="dropdown-content">
                                                <a href="#" onClick={logoutHandle}>Log Out</a>
                                                <Link to="/Profiles">Profile</Link>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <Nav.Link href="#features" className="signinbuttionsection">
                                        {" "}
                                        <Link className="nev-color" to="/account/sign-in">
                                            <button className="sign-up-button"><LiaUserSolid />Sign In</button>
                                        </Link>
                                    </Nav.Link>
                                )}
                                {token ? (
                                    <>
                                    </>
                                ) : (
                                    <Nav.Link href="#features" className="signinbuttion">
                                        <Link className="nev-color" to="/account/sign-in">
                                            <button className="sign-up-button"><LiaUserSolid />Sign In</button>
                                        </Link>
                                    </Nav.Link>
                                )}
                            </div> */}
                            <div className='Search-bag-con'>
                                <div className='navbar-addtobagbag-con'>
                                    <button
                                        class="btn btn-primary"
                                        type="button"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasRight"
                                        aria-controls="offcanvasRight"
                                    >
                                        <IoBagOutline />
                                    </button>
                                    <span>({cartValue.length})</span>
                                </div>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                    <div className='SmallSLogo'>
                        <div className='mid-logo'>
                            <div className='LogoImage'>
                                <img src={Logo} alt="" />
                            </div>
                        </div>
                    </div>
                </Container>
            </Navbar>

            {/* Render the AddtoCart component */}
            <AddtoCart />
        </>
    )
}

export default NavBar