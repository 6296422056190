import React, { useEffect, useLayoutEffect, useState } from 'react';
import "../Css/ProductList.css";
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import Footer from '../Component/Footer';
import Productcart from './Productcart';
import axios from 'axios';
import { Pagination, Spin } from 'antd';
// import { useProductContext } from '../Context/ProContext';
import searcherror from "../Images/search-error.webp";
import { useNavigate, useParams } from 'react-router-dom';

const ProductList = () => {
    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { slugs } = useParams();
    const [loading, setLoading] = useState(false);
    const [sortedProducts, setSortedProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(6);
    const [selectedCategory, setSelectedCategory] = useState(slugs || "all");
    const [searchText, setSearchText] = useState("");
    const [total, setTotal] = useState(0);

    console.log("slugs",slugs)

    const fetchData = async (page, limit, selectedCategory, searchText,slugs) => {
        try {
            setLoading(true);
            const response = await axios.get(`https://jutiepie.in/api/product.php?&action=productFetch&limit=${limit}&page=${page}${searchText !== "" ? "&search=" + searchText : ""}${"&category_slug=" + slugs}`);
            console.log("response",response)
            if (response) {
                setLoading(false);
                setSortedProducts(response.data.list);
                setTotal(response.data.total);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const debounceData = setTimeout(() => {
            fetchData(page, limit, selectedCategory, searchText, slugs);
        }, 300);

        return () => clearTimeout(debounceData);
    }, [page, limit, selectedCategory, searchText, slugs]);

    const [categoryData, setCategoryData] = useState([]);

    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                const response = await axios.get('https://jutiepie.in/api/category.php?&action=categoryFetch');
                setCategoryData(response.data.list);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchCategoryData();
    }, []);

    const CategorySet = (categorySlug) => {
        // console.log("categorySlug",categorySlug)
        setSelectedCategory(categorySlug);
        navigate(`/Product/${categorySlug}`);
        setPage(1);
    };

    return (
        <>
            <section className='ProductList-Section-1'>
                <div className='container'>
                    <div className='ProductList-Section-con'>
                        <div className='ProductList-Section-Text'>
                            <h2>Products</h2>
                            <div className='ProductList-Section-NavPage'>
                                <span><AiFillHome />Home</span>
                                <h3><MdKeyboardArrowRight /></h3>
                                <h1 className="d-none">Best Salon in Ghatkopar</h1>
                                <h2>Products</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='ProductList-Section-2'>
                <div className='container'>
                    <div className='ProductList-Section-2-con'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-3 col-sm-12'>
                                <div className='ProductList-Side-panel'>
                                    <div className='DefaultSorting-ProductList-con'>
                                        <div className='DefaultSorting-ProductList'>
                                            <h2>Default Sorting</h2>
                                        </div>
                                        <div className='DefaultSorting-radio-inputs-con'>
                                            <div className='searchproductpage'>
                                                <div className="wrap">
                                                    <div className="search">
                                                        <input
                                                            type="text"
                                                            className="searchTerm"
                                                            placeholder="What are you looking for?"
                                                            value={searchText}
                                                            onChange={(e) => {
                                                                setSearchText(e.target.value);
                                                                setPage(1);
                                                            }}
                                                        />
                                                        <button type="submit" className="searchButton">
                                                            <i className="fa fa-search"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='Categories-ProductList-con'>
                                        <div className='Categories-ProductList'>
                                            <h2>Category</h2>
                                        </div>
                                        <div className='Categories-ProductList-input-con' id="style-1">
                                            <ul>
                                                <li
                                                    onClick={() => CategorySet("all")}
                                                    style={{
                                                        background: selectedCategory === "all" ? 'whitesmoke' : '',
                                                        color: selectedCategory === "all" ? '#821F40' : ''
                                                    }}
                                                >
                                                    <div className='Categories-ProductList-input-inside'>
                                                        <h3>All</h3>
                                                    </div>
                                                </li>
                                                {categoryData.map((category) => (
                                                    <li
                                                        key={category.Category_Id}
                                                        onClick={() => CategorySet(category.Category_Slugs)}
                                                        style={{
                                                            background: selectedCategory === category.Category_Slugs ? 'whitesmoke' : '',
                                                            color: selectedCategory === category.Category_Slugs ? '#821F40' : ''
                                                        }}
                                                    >
                                                        <div className='Categories-ProductList-input-inside'>
                                                            <h3>{category.Category_Type}</h3>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-9 col-md-9 col-sm-12'>
                                <Spin spinning={loading}>
                                    <div className='Product-list-main-con'>
                                        <div className='Product-list-main-con-head'>
                                            <h2>Product List</h2>
                                        </div>
                                        <div className='Product-card-list-main'>
                                            <div className='row'>
                                                {sortedProducts.length > 0 ? (
                                                    sortedProducts.map((product) => (
                                                        <div className="col-lg-4 col-sm-6 my-3" key={product.productId}>
                                                            <Productcart product={product} />
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="col-12">
                                                        <div className='search-error-image'>
                                                            <div className='search-error-image-con'>
                                                                <img src={searcherror} alt='search error' />
                                                            </div>
                                                        </div>
                                                        <p className="text-center search-error-text">Please search a valid product name.</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <Pagination
                                            hideOnSinglePage
                                            current={page}
                                            total={total}
                                            pageSize={limit}
                                            onChange={(page) => setPage(page)}
                                        />
                                    </div>
                                </Spin>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ProductList;
